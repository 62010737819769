import { useEffect, useState } from "react";
import supabase from "./supabase";

import "./style.css";

function App() {
  const [showForm, setShowForm] = useState(false);
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("all");

  useEffect(
    function () {
      async function getComments() {
        setIsLoading(true);

        let query = supabase.from("comments").select("*");

        if (currentCategory !== "all")
          query = query.eq("category", currentCategory);

        const { data: comments, error } = await query
          .order("votesInteresting", { ascending: false })
          .limit(1000);

        if (!error) setComments(comments);
        else alert("There was a problem getting data");
        setIsLoading(false);
      }
      getComments();
    },
    [currentCategory]
  );

  return (
    <>
      {/* HEADER */}
      <Header showForm={showForm} setShowForm={setShowForm} />
      {showForm ? (
        <NewCommentForm setComments={setComments} setShowForm={setShowForm} />
      ) : null}

      <main className="main">
        <CategoryFilter setCurrentCategory={setCurrentCategory} />

        {isLoading ? (
          <Loader />
        ) : (
          <CommentList comments={comments} setComments={setComments} />
        )}
      </main>
    </>
  );
}

function Loader() {
  return <p className="message">Loading...</p>;
}

function Header({ showForm, setShowForm }) {
  const appTitle = "Nicholas Yebra";

  return (
    <header className="header">
      <div className="logo">
        <img src="ylogo.png" height="74" width="74" alt="Yebra Logo" />
        <h1>{appTitle}</h1>
      </div>

      <button
        className="btn btn-large btn-open"
        onClick={() => setShowForm((show) => !show)}
      >
        {showForm ? "Close" : "Leave a Comment"}
      </button>
    </header>
  );
}

const CATEGORIES = [
  { name: "about me", color: "#fff1f2" },
  { name: "resume", color: "#fefce8" },
  { name: "projects", color: "#f0fdf4" },
  { name: "linkedin", color: "#f0fdfa" },
  { name: "run for spencer", color: "#f0f9ff" },
  { name: "atlanta 2 antarctica", color: "#eff6ff" },
  { name: "instagram", color: "#f5f3ff" },
];

/*function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}
*/
function NewCommentForm({ setComments, setShowForm }) {
  const [text, setText] = useState("");
  // Fixed in a video text overlay
  const [handle, setHandle] = useState("");
  const [category, setCategory] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const textLength = text.length;

  async function handleSubmit(e) {
    // 1. Prevent browser reload
    e.preventDefault();
    console.log(text, handle, category);

    // 2. Check if data is valid. If so, create a new comment
    if (text && handle && category && textLength <= 200) {
      // 3. Upload comment to Supabase and receive the new comment object
      setIsUploading(true);
      const { data: newComment, error } = await supabase
        .from("comments")
        .insert([{ text, handle, category }])
        .select();
      setIsUploading(false);

      // 4. Add the new comment to the UI: add the comment to state
      if (!error) setComments((comments) => [newComment[0], ...comments]);

      // 5. Reset input fields
      setText("");
      setHandle("");
      setCategory("");

      // 6. Close the form
      setShowForm(false);
    }
  }

  return (
    <form className="comment-form" onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Leave a Comment..."
        value={text}
        onChange={(e) => setText(e.target.value)}
        disabled={isUploading}
      />
      <span>{200 - textLength}</span>
      <input
        value={handle}
        type="text"
        placeholder="Handle"
        onChange={(e) => setHandle(e.target.value)}
        disabled={isUploading}
      />
      <select
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        disabled={isUploading}
      >
        <option value="">Choose category:</option>
        {CATEGORIES.map((cat) => (
          <option key={cat.name} value={cat.name}>
            {cat.name.toUpperCase()}
          </option>
        ))}
      </select>
      <button className="btn btn-large" disabled={isUploading}>
        Post
      </button>
    </form>
  );
}

function CategoryFilter({ setCurrentCategory }) {
  return (
    <aside>
      <ul>
        <li className="category">
          <button
            className="btn btn-all-categories"
            onClick={() => setCurrentCategory("all")}
          >
            All
          </button>
        </li>

        {CATEGORIES.map((cat) => (
          <li key={cat.name} className="category">
            <button
              className="btn btn-category"
              style={{ backgroundColor: cat.color }}
              onClick={() => setCurrentCategory(cat.name)}
            >
              {cat.name}
            </button>
          </li>
        ))}
      </ul>
    </aside>
  );
}

function CommentList({ comments, setComments }) {
  if (comments.length === 0)
    return (
      <p className="message">
        No comments for this category yet! Create the first one ✌️
      </p>
    );

  return (
    <section>
      <ul className="comments-list">
        {comments.map((comment) => (
          <Comment
            key={comment.id}
            comment={comment}
            setComments={setComments}
          />
        ))}
      </ul>
      <p>There are {comments.length} comments in the database. Add your own!</p>
    </section>
  );
}

function Comment({ comment, setComments }) {
  const [isUpdating, setIsUpdating] = useState(false);
  const isDisputed =
    comment.votesInteresting + comment.votesMindblowing < comment.votesFalse;

  async function handleVote(columnName) {
    setIsUpdating(true);
    const { data: updatedComment, error } = await supabase
      .from("comments")
      .update({ [columnName]: comment[columnName] + 1 })
      .eq("id", comment.id)
      .select();
    setIsUpdating(false);

    if (!error)
      setComments((comments) =>
        comments.map((f) => (f.id === comment.id ? updatedComment[0] : f))
      );
  }

  return (
    <li className="comment">
      <p>
        {isDisputed ? <span className="disputed">[⛔️ DISPUTED]</span> : null}
        {comment.text}
        <a className="handle" href={comment.handle} target="_blank">
          (Handle)
        </a>
      </p>
      <span
        className="tag"
        style={{
          backgroundColor: CATEGORIES.find(
            (cat) => cat.name === comment.category
          ).color,
        }}
      >
        {comment.category}
      </span>
      <div className="vote-buttons">
        <button
          onClick={() => handleVote("votesInteresting")}
          disabled={isUpdating}
        >
          👍 {comment.votesInteresting}
        </button>
        <button
          onClick={() => handleVote("votesMindblowing")}
          disabled={isUpdating}
        >
          🤯 {comment.votesMindblowing}
        </button>
        <button onClick={() => handleVote("votesFalse")} disabled={isUpdating}>
          ⛔️ {comment.votesFalse}
        </button>
      </div>
    </li>
  );
}

export default App;
